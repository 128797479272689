//Variables Globales
var contenedorTitulo;

function createElements() {
  // Crea elemtos para el contenido colapsable
  jQuery('.panel-heading').each(function(i) {
      jQuery(this).attr('id', 'heading0' + (i+1));
  });
  jQuery('.panel-collapse').each(function(i) {
      jQuery(this).attr('id', 'collapse0' + (i+1));
  });
  jQuery('.panel-collapse').each(function(i) {
      jQuery(this).attr('aria-labelledby', 'heading0' + (i+1));
  });
  jQuery('.catchA').each(function(i) {
      jQuery(this).attr('href', '#collapse0' + (i+1));
  });
  jQuery('.catchA').each(function(i) {
      jQuery(this).attr('aria-controls', 'collapse0' + (i+1));
  });
  jQuery('#collapse01').addClass('in');
  jQuery('#heading01 a').attr('aria-expanded', 'true');
  jQuery('#heading01 a').removeClass('collapsed');
}

function itemsActividades() {
  //Calcula position top de items de actividades
  jQuery('.actContainer').imagesLoaded(function() {
    jQuery('.actContainer').each(function(){
      var altoActContainer = jQuery(this).height();
      var altoActTitulo = jQuery(this).find('.actContentContainer h3').height() + 54;
      jQuery(this).find('.actContentContainer').css('top',altoActContainer - altoActTitulo);
    });
  });

  // calcula ancho de caja con vinculos de items de actividades
  var anchoActContainer = jQuery('.actContentContainer').width();
  jQuery('.actContainer .actContentContainer03').css('width',anchoActContainer);
}

function crearDesplegables() {
  
  jQuery('.contentSection').each(function(){
    var nombrePestana = jQuery(this).find('.secTitle').html();
    
    var contenidoPestana = jQuery(this).find('.capturarContenido').html();

    jQuery(this).addClass('panel panel-default');
    jQuery(this).find('.secTitle').after('<div class="panel-heading" role="tab"><h4 class="panel-title"><a class="catchA collapsed" role="button" data-toggle="collapse" data-parent="#accordion" aria-expanded="false">' + nombrePestana + '</a></h4></div>');
    jQuery(this).find('.panel-heading').after('<div class="panel-collapse collapse" role="tabpanel"><div class="panel-body">' + contenidoPestana + '</div></div>');

    jQuery(this).find('.secTitle').remove();
    jQuery(this).find('.capturarContenido').remove();
  });
  jQuery('.contentSection.panel').wrapAll('<div class="panel-group accordionHome" id="accordion" role="tablist" aria-multiselectable="true"></div>');
  
  createElements();
}


jQuery(document).ready(function(){

  var divs = jQuery('.agruparDivs > div');
  for(var i = 0; i < divs.length; i+=3) {
    divs.slice(i, i+3).wrapAll('<div class="row hideme"></div>');
  }

  jQuery('.fc-icon.fc-icon-left-single-arrow').html('<i class="fa fa-angle-left" aria-hidden="true"></i> Semana anterior');
  jQuery('.fc-icon.fc-icon-right-single-arrow').html('Semana siguiente <i class="fa fa-angle-right" aria-hidden="true"></i>');

  jQuery('.showCalendario').click(function(){
    id_espacio = jQuery(this).data('id');
    jQuery('.calendario'+id_espacio).toggle();
  });

  NProgress.start();
  setTimeout(function() {
    NProgress.done();
    jQuery('body').css({
      'opacity':'1',
      'margin-top':'0'
    });
  }, 1000);

  jQuery('.openSubmenu a').click(function(){
    jQuery(this).parent().find('.sub-menu').slideToggle(500);
  });

  jQuery('#menu-menu-principal .menu-item-has-children').hover(function() {
      jQuery(this).children('.sub-menu').fadeIn('fast');
    }, function() {
      jQuery(this).children('.sub-menu').fadeOut('fast');
    }
  );

  var anchoContainerLogo = jQuery('.clientesConatinerUl > div').width();
  jQuery('.clientesConatinerUl .area').width(anchoContainerLogo);

  jQuery('.thumbnail-caption').append('<span class="verGaleria">Ver galería</span>');

  jQuery('.qtranxs_flag_en').prepend('<img src="/wp-content/themes/centroeventos/assets/img/united-kingdom.svg" alt="English" title="English">');
  jQuery('.qtranxs_flag_es').prepend('<img src="/wp-content/themes/centroeventos/assets/img/spain.svg" alt="Español" title="Español">');

  //Agrega clase transicion
  jQuery('header .menuPrincipal a').addClass('transicion');
  jQuery('.boton-naranja a').addClass('transicion');
  jQuery('.qtranxs_flag_and_text').addClass('transicion');

  // Ordena los logos de los clientes para armar carrusel
  // var divs = jQuery('.clientesConatinerUl > div');
  // for(var i = 0; i < divs.length; i+=12) {
  //   divs.slice(i, i+12).wrapAll('<li class="logosContainer"></li>');
  // }

  //Agrega clase de estilos bootstrap a las tablas
  jQuery('.tab-content table').addClass('table table-striped');
  jQuery('.table-striped').wrap('<div class="table-responsive"></div>');

  // Navegancion landing nuestros centros
  jQuery('.anchorNav a').click(function(evn){
      evn.preventDefault();
      jQuery('html,body').scrollTo(this.hash, this.hash);
  });

  // Crea elemtos para el menu superior
  jQuery('#menu-menu-superior .caret-down > a').append('<i class="ion-ios-arrow-down"></i>');
  jQuery('#menu-menu-superior .caret-right > a').append('<i class="ion-ios-arrow-right"></i>');
  jQuery('#menu-menu-superior .menu-item-has-children').hover(function() {
      jQuery(this).children('.sub-menu').fadeIn('fast');
    }, function() {
      jQuery(this).children('.sub-menu').fadeOut('fast');
    }
  );

  // Crea elemtos para el menu principal
  jQuery('#menu-menu-principal .caret-down > a').append('<i class="ion-ios-arrow-down"></i>');
  jQuery('#menu-menu-principal .caret-right > a').append('<i class="ion-ios-arrow-right"></i>');
  jQuery('#menu-menu-principal .menu-item-has-children').hover(function() {
      jQuery(this).children('.sub-menu').fadeIn('fast');
    }, function() {
      jQuery(this).children('.sub-menu').fadeOut('fast');
    }
  );

  var alturaDivName = jQuery('.placeResultItemBody .alturaMax').height();
  jQuery('.placeResultItemBody .col').height(alturaDivName);

  jQuery('.nav-tabs li:first-child').addClass('active');
  jQuery('.tab-content .tab-pane:first-child').addClass('in active');

  jQuery('.nav-tabs li').each(function(i) {
    jQuery(this).find('a').attr('href', '#ntab0' + (i+1));
  });

  jQuery('.tab-content .tab-pane').each(function(i) {
    jQuery(this).attr('id', 'ntab0' + (i+1));
  });

  // Crea slider de imagenes de centros

  

  // Crea slider de clientes
  // jQuery('.clientesConatinerUl').imagesLoaded(function() {
  //   jQuery('.clientesConatinerUl').carouFredSel({
  //     responsive: true,
  //     items: 1,
  //     pagination: "#pager2",
  //     scroll: {
  //       items: 1,
  //       duration: 1000,
  //       pauseOnHover: true,
  //       easing: "quadratic"
  //     }
  //   });
  // });

  itemsActividades();
  createElements();

  jQuery(window).resize(function(){
    itemsActividades();
  });

  if(jQuery(window).width()>767){

    jQuery('.visible-xs').remove();

    // Crea slider principal home
    jQuery('#sliderCarousel').imagesLoaded(function() {
      jQuery('#sliderCarousel').carouFredSel({
        responsive: true,
        items: 1,
        pagination: {
          anchorBuilder: function(nr) {
            contenedorTitulo = jQuery('.contSliderCarousel li.slideImgBack .contenedorTitulo').eq(nr-1).html();
            return '<a href="#"><span>'+contenedorTitulo+'</span></a>';
          }
          , container : "#pager1",
        },
        scroll: {
          items: 1,
          duration: 1000,
          pauseOnHover: true,
          easing: "quadratic"
        }
      });
    });
    jQuery('#ubicGal').imagesLoaded(function() {
      jQuery('#ubicGal').carouFredSel({
        width: '100%',
        responsive: true,
        items: 1,
        prev: '#prev10',
        next: '#next10',
        scroll: {
          items: 1,
          duration: 1000,
          pauseOnHover: true,
          easing: "quadratic"
        }
      });
    });

    jQuery(window).scroll( function(){
      /* Check the location of each desired element */
      jQuery('.hideme').each( function(i){
          var bottom_of_objecta = jQuery(this).offset().top + (jQuery(this).outerHeight()*.1);
          var bottom_of_windowa = jQuery(window).scrollTop() + jQuery(window).height();
          /* If the object is completely visible in the window, fade it it */
          if( bottom_of_windowa > bottom_of_objecta ){
              TweenLite.to(this, 2, {y: 0, autoAlpha: 1, ease: Power4.easeOut});
          }
          else if( bottom_of_windowa < bottom_of_objecta ){
              TweenLite.to(this, 2, {y: 60, autoAlpha: 0, ease: Power4.easeOut});
          }
      });
    });

  }else if (jQuery(window).width()<767) {
    jQuery('#sliderCarousel').imagesLoaded(function() {
      jQuery('#sliderCarousel').carouFredSel({
        responsive: true,
        items: 1,
        prev: '#prev2',
			  next: '#next2',
        // pagination: {
        //   anchorBuilder: function(nr) {
        //     contenedorTitulo = jQuery('.contSliderCarousel .bannerMovil h2').eq(nr-1).html();
        //     if (nr === 4) {
        //       break;
        //     }
        //     return '<a href="#"><span>'+contenedorTitulo+'</span></a>';
        //   }
        //   , container : "#pager1",
        // },
        scroll: {
          items: 1,
          duration: 1000,
          pauseOnHover: true,
          easing: "quadratic"
        }
      });
    });

    jQuery('.placeResultItemBody').find('.col-height').removeClass('col-height').removeClass('col-middle');
    jQuery('.placeResultItemBody').find('.row-height').removeClass('row-height');

    crearDesplegables();

    jQuery('.accordionHome').on('shown.bs.collapse', function () {
  	  var panel = jQuery(this).find('.in');
  	  jQuery('html, body').animate({
        scrollTop: panel.offset().top - 110
  	  }, 500);
  	});

    jQuery('.accordionSingle').on('shown.bs.collapse', function () {
  	  var panel = jQuery(this).find('.in');
  	  jQuery('html, body').animate({
        scrollTop: panel.offset().top - 95
  	  }, 500);
    });
    jQuery('#heading04').on('click',function(){
      if ((jQuery('.caroufredsel_wrapper>#ubicGal').length)<1) {
        console.log('ahora voy a crear el carousel');
        
        jQuery('#ubicGal').imagesLoaded(function() {
          jQuery('#ubicGal').carouFredSel({
            width: '100%',
            responsive: true,
            items: 1,
            prev: '#prev10',
            next: '#next10',
            scroll: {
              items: 1,
              duration: 1000,
              pauseOnHover: true,
              easing: "quadratic"
            }
          });
        });
      }
    });
  }

});
